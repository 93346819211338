import React from "react";
import ReportsMainBanner from "../templates/InvestorDesktop/ReportsAndPresentation/ReportsMainBanner";
import ReportsSideMenu from "../templates/InvestorDesktop/ReportsAndPresentation/ReportsSideMenu";
import ReportMainComponent from "../templates/InvestorDesktop/ReportsAndPresentation/ReportMainComponent";

export default function ReportsAndPresentation() {
  return (
    <div>
      <ReportsMainBanner />
      <ReportMainComponent />
    </div>
  );
}
