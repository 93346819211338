import React, { useState, useEffect } from "react";
import ReportsSideMenu from "./ReportsSideMenu";
import AnnualReports from "./AnnualReports";
import QuarterlyReports from "./QuarterlyReports";
import BriefingPressRelease from "./BriefingPressRelease";
import PeriodicPresentation from "./PeriodicPresentation";
import Webinar from "./Webinar";
import AnalystReports from "./AnalystReports";
import CompanyPolicies from "./CompanyPolicies";
import "./ReportMainComponent.css";

const ReportMainComponent = () => {
  // Check if we are in the browser before accessing localStorage
  const getSavedPage = () =>
    typeof window !== "undefined"
      ? localStorage.getItem("selectedPage")
      : "annual-reports";

  const [page, setPage] = useState(getSavedPage);

  useEffect(() => {
    // Save the selected page to localStorage in the browser
    if (typeof window !== "undefined") {
      localStorage.setItem("selectedPage", page);
    }
  }, [page]);

  const renderContent = () => {
    switch (page) {
      case "annual-reports":
        return <AnnualReports />;
      case "quarterly-reports":
        return <QuarterlyReports />;
      case "briefing-press-release":
        return <BriefingPressRelease />;
      case "periodic-presentation":
        return <PeriodicPresentation />;
      case "webinar":
        return <Webinar />;
      case "analyst-reports":
        return <AnalystReports />;
      case "company-policies":
        return <CompanyPolicies />;
      default:
        return <AnnualReports />;
    }
  };

  return (
    <div className="reports-page-container">
      <ReportsSideMenu setPage={setPage} />
      <div className="reportandpresentaion-content">{renderContent()}</div>
    </div>
  );
};

export default ReportMainComponent;
