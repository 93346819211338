import React from "react";
import "./CompanyPolicies.css";

const ComapnyPolicies = () => {
  return (
    <div>
      <h2>Comapny Policies</h2>
      <p>This is the content for the Comapny Policies page.</p>
    </div>
  );
};

export default ComapnyPolicies;
