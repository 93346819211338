import React, { useEffect } from "react";
import "./Webinar.css";
import calendarIcon from "../../../images/calendar.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Webinar = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);
  const webinarData = [
    {
      year: "FY 24/25",
      quarters: [
        {
          number: 1,
          date: "14-08-2024",
        },
      ],
    },
    {
      year: "FY 23/24",
      quarters: [
        {
          number: 1,
          date: "09-08-2023",
        },
        {
          number: 2,
          date: "07-11-2023",
        },
        {
          number: 3,
          date: "09-02-2024",
        },
        {
          number: 4,
          date: "04-06-2024",
        },
      ],
    },
    {
      year: "FY 22/23",
      quarters: [
        {
          number: 1,
          date: "16-08-2022",
        },
        {
          number: 2,
          date: "08-11-2022",
        },
        {
          number: 3,
          date: "03-02-2023",
        },
        {
          number: 4,
          date: "13-07-2023",
        },
      ],
    },
  ];

  // Function to get suffix for each quarter
  const getSuffix = (number) => {
    if (number === 1) return "st";
    if (number === 2) return "nd";
    if (number === 3) return "rd";
    return "th";
  };

  return (
    <div className="webinar">
      <h2 className="webinar-title" data-aos="fade-left">
        Quarterly Performance Reports
      </h2>
      <p className="webinar-page-title" data-aos="fade-left">
        Webinar
      </p>

      {webinarData.map((fy, index) => (
        <div key={index} className="webinar-fy" data-aos="fade-up">
          <h3 className="webinar-fy-title">{fy.year}</h3>
          <hr className="webinar-divider" />
          <div className="webinar-quarter-cards">
            {fy.quarters.map((quarter, qIndex) => (
              <div
                key={qIndex}
                className="webinar-quarter-card"
                data-aos="fade-left"
                data-aos-delay={qIndex * 100}
              >
                <div className="webinar-quarter-header">
                  {quarter.number}
                  <sup>{getSuffix(quarter.number)}</sup> Quarter
                  <img
                    src={calendarIcon}
                    alt="Calendar Icon"
                    className="webinar-calendar-icon"
                  />
                </div>
                <p className="webinar-date">
                  Webinar Date
                  <br />
                  <span className="webinar-date-date">{quarter.date}</span>
                </p>
                <button className="webinar-button hover-effect">
                  Watch Webinar
                  <span className="webinar-arrow hover-effect"></span>
                </button>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Webinar;
