import React from "react";
import "./ReportsMainBanner.css";
import QuarterlyImage from "../../../images/QuaterlyMainBanner.jpeg";

export default function ReportsMainBanner() {
  return (
    <div>
      <div>
        <div className="quarterlyReportMain">
          <img
            className="quarterlyReportMain-home-image"
            alt=""
            src={QuarterlyImage}
          />
          <div className="quarterlyReportMain-image-text-box">
            <div className="quarterlyReportMain-image-background-text">
              <h1 className="quarterlyReportMain-image-topic">
                Reports & Presentations
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
