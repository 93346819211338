import React, { useState, useEffect } from "react";
import "./ReportsSideMenu.css";

const ReportsSideMenu = ({ setPage }) => {
  // Retrieve saved expanded menu and active page from localStorage
  const getSavedExpandedMenu = () =>
    typeof window !== "undefined" ? localStorage.getItem("expandedMenu") : null;

  const getSavedActivePage = () =>
    typeof window !== "undefined" ? localStorage.getItem("activePage") : null;

  const [expandedMenu, setExpandedMenu] = useState(getSavedExpandedMenu);
  const [activePage, setActivePage] = useState(getSavedActivePage);

  const handleMenuClick = (menuName) => {
    const newMenu = expandedMenu === menuName ? null : menuName;
    setExpandedMenu(newMenu);
    if (typeof window !== "undefined") {
      localStorage.setItem("expandedMenu", newMenu);
    }
  };

  const handleSubMenuClick = (page, event) => {
    event.stopPropagation();
    setPage(page);
    setActivePage(page);
    setExpandedMenu("quarterly-reports");
    if (typeof window !== "undefined") {
      localStorage.setItem("activePage", page);
      localStorage.setItem("expandedMenu", "quarterly-reports");
    }
  };

  useEffect(() => {
    // Sync expanded menu and active page states with localStorage
    if (typeof window !== "undefined") {
      setExpandedMenu(localStorage.getItem("expandedMenu"));
      setActivePage(localStorage.getItem("activePage"));
    }
  }, []);

  if (!setPage) {
    return null;
  }

  return (
    <div className="side-menu">
      <ul className="menu-list">
        {/* Annual Reports */}
        <li
          className={`menu-item ${
            activePage === "annual-reports" ? "active" : ""
          }`}
          onClick={() => {
            setPage("annual-reports");
            setActivePage("annual-reports");
            setExpandedMenu(null);
            if (typeof window !== "undefined") {
              localStorage.setItem("activePage", "annual-reports");
              localStorage.removeItem("expandedMenu");
            }
          }}
        >
          Annual Reports
        </li>

        {/* Quarterly Performance Reports */}
        <li
          className={`menu-item ${
            expandedMenu === "quarterly-reports" ? "expanded" : ""
          }`}
          onClick={() => handleMenuClick("quarterly-reports")}
        >
          Quarterly Performance Reports
          {expandedMenu === "quarterly-reports" && (
            <ul className="submenu expanded">
              <li
                className={`submenu-item ${
                  activePage === "quarterly-reports" ? "active" : ""
                }`}
                onClick={(e) => handleSubMenuClick("quarterly-reports", e)}
              >
                Reports
              </li>
              <li
                className={`submenu-item ${
                  activePage === "briefing-press-release" ? "active" : ""
                }`}
                onClick={(e) => handleSubMenuClick("briefing-press-release", e)}
              >
                Briefing Press Release
              </li>
              <li
                className={`submenu-item ${
                  activePage === "periodic-presentation" ? "active" : ""
                }`}
                onClick={(e) => handleSubMenuClick("periodic-presentation", e)}
              >
                Periodic Presentation
              </li>
              <li
                className={`submenu-item ${
                  activePage === "webinar" ? "active" : ""
                }`}
                onClick={(e) => handleSubMenuClick("webinar", e)}
              >
                Webinar
              </li>
            </ul>
          )}
        </li>

        {/* Analyst Reports */}
        <li
          className={`menu-item ${
            activePage === "analyst-reports" ? "active" : ""
          }`}
          onClick={() => {
            setPage("analyst-reports");
            setActivePage("analyst-reports");
            setExpandedMenu(null);
            if (typeof window !== "undefined") {
              localStorage.setItem("activePage", "analyst-reports");
              localStorage.removeItem("expandedMenu");
            }
          }}
        >
          Analyst Reports
        </li>

        {/* Company Policies */}
        <li
          className={`menu-item ${
            activePage === "company-policies" ? "active" : ""
          }`}
          onClick={() => {
            setPage("company-policies");
            setActivePage("company-policies");
            setExpandedMenu(null);
            if (typeof window !== "undefined") {
              localStorage.setItem("activePage", "company-policies");
              localStorage.removeItem("expandedMenu");
            }
          }}
        >
          Company Policies
        </li>
      </ul>
    </div>
  );
};

export default ReportsSideMenu;
