import React from "react";
import "./AnalystReports.css";

const AnalystReport = () => {
  return (
    <div>
      <h2>Analyst Reports</h2>
      <p>This is the content for the Analyst Reports page.</p>
    </div>
  );
};

export default AnalystReport;
