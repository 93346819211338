import React, { useState, useEffect } from "react";
import "./QuarterlyReports.css";
import CiCReportCover2023 from "../../../images/CICReportCover2023-2024.jpeg";
import QuaterlyFinancialReport31032024 from "../../../images/QuarterlyFinancialReport-31-03-2024.jpeg";
import QuaterlyFinancialReport30092023 from "../../../images/QuaterlyFinancialReport-30-09-2023.jpeg";
import QuaterlyFinancialReport30062023 from "../../../images/QuaterlyFinancialReport-30-09-2023.jpeg";
import CICQReport31032023 from "../../../images/CICQReport-31-03-2023.jpeg";
import InterimFinancialStatement03062020 from "../../../images/Interim-Financial-Statement-30-06-2020.jpeg";
import ArrowDropDownOpen from "../../../images/DropDownArrow.svg";
import ArrowDropDownClose from "../../../images/DropDownArrowClose.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const currentReportData = [
  {
    id: 1,
    title: "Interim Financial Statements",
    date: "30-06-2024",
    image: CiCReportCover2023,
  },
  {
    id: 2,
    title: "Interim Financial Statements",
    date: "31-03-2024",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 3,
    title: "Interim Financial Statements",
    date: "31-12-2023",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 4,
    title: "Interim Financial Statements",
    date: "30-09-2023",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 5,
    title: "Quarterly Financial Report",
    date: "30-06-2023",
    image: QuaterlyFinancialReport30062023,
  },
  {
    id: 6,
    title: "Quarterly Financial Report",
    date: "31-03-2023",
    image: CICQReport31032023,
  },
  {
    id: 7,
    title: "Quarterly Financial Report",
    date: "31-12-2022",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 8,
    title: "Quarterly Financial Report",
    date: "30-09-2022",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 9,
    title: "Quarterly Financial Report",
    date: "30-06-2022",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 10,
    title: "Quarterly Financial Report",
    date: "31-03-2022",
    image: CiCReportCover2023,
  },
  {
    id: 11,
    title: "Quarterly Financial Report",
    date: "31-12-2021",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 12,
    title: "Quarterly Financial Report",
    date: "30-09-2021",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 13,
    title: "Quarterly Financial Report",
    date: "30-06-2021",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 14,
    title: "Quarterly Financial Report",
    date: "31-03-2021",
    image: CiCReportCover2023,
  },
  {
    id: 15,
    title: "Quarterly Financial Report",
    date: "31-03-2021",
    image: CiCReportCover2023,
  },
  {
    id: 16,
    title: "Quarterly Financial Report",
    date: "31-03-2021",
    image: CiCReportCover2023,
  },
  {
    id: 17,
    title: "Quarterly Financial Report",
    date: "31-12-2021",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 18,
    title: "Quarterly Financial Report",
    date: "30-09-2021",
    image: InterimFinancialStatement03062020,
  },
];

const reportData2019 = [
  {
    id: 1,
    title: "Quarterly Financial Report",
    date: "31-12-2018",
    image: CiCReportCover2023,
  },
  {
    id: 2,
    title: "Quarterly Financial Report",
    date: "30-09-2018",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 3,
    title: "Quarterly Financial Report",
    date: "31-03-2018",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 4,
    title: "Quarterly Financial Report",
    date: "31-12-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 5,
    title: "Quarterly Financial Report",
    date: "30-09-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 6,
    title: "Quarterly Financial Report",
    date: "31-03-2019",
    image: InterimFinancialStatement03062020,
  },
];

const reportData2018 = [
  {
    id: 1,
    title: "Quarterly Financial Report",
    date: "31-12-2018",
    image: CiCReportCover2023,
  },
  {
    id: 2,
    title: "Quarterly Financial Report",
    date: "30-09-2018",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 3,
    title: "Quarterly Financial Report",
    date: "31-03-2018",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 4,
    title: "Quarterly Financial Report",
    date: "31-12-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 5,
    title: "Quarterly Financial Report",
    date: "30-09-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 6,
    title: "Quarterly Financial Report",
    date: "31-03-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 7,
    title: "Quarterly Financial Report",
    date: "30-09-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 8,
    title: "Quarterly Financial Report",
    date: "31-03-2019",
    image: InterimFinancialStatement03062020,
  },
];

const reportData2017 = [
  {
    id: 1,
    title: "Quarterly Financial Report",
    date: "31-12-2018",
    image: CiCReportCover2023,
  },
  {
    id: 2,
    title: "Quarterly Financial Report",
    date: "30-09-2018",
    image: QuaterlyFinancialReport30092023,
  },
  {
    id: 3,
    title: "Quarterly Financial Report",
    date: "31-03-2018",
    image: QuaterlyFinancialReport31032024,
  },
  {
    id: 4,
    title: "Quarterly Financial Report",
    date: "31-12-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 5,
    title: "Quarterly Financial Report",
    date: "30-09-2019",
    image: InterimFinancialStatement03062020,
  },
  {
    id: 6,
    title: "Quarterly Financial Report",
    date: "31-03-2019",
    image: InterimFinancialStatement03062020,
  },
];

const QuarterlyReports = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const [openYear, setOpenYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 6;
  const totalPages = Math.ceil(currentReportData.length / reportsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      setCurrentPage(1);
    }
  };

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = currentReportData.slice(
    indexOfFirstReport,
    indexOfLastReport
  );

  return (
    <div className="qr-year-report-layout">
      <h1 className="qr-quarterly-report-heading" data-aos="fade-left">
        Quarterly Performance Reports
      </h1>
      <h2 className="quarterly-report-subheading" data-aos="fade-left">
        Reports
      </h2>

      <div className="qr-report-container">
        <div className="qr-report-grid">
          {currentReports.map((report) => (
            <div
              key={report.id}
              className="qr-report-item"
              data-aos="fade-left"
              data-aos-delay={report.id * 100}
            >
              <img
                src={report.image}
                alt={report.title}
                className="qr-report-image"
              />
              <div className="qr-report-title">{report.title}</div>
              <div className="qr-report-date">{report.date}</div>
            </div>
          ))}
        </div>
        <div className="qr-next-button-container" onClick={handleNextPage}>
          <div className="qr-next-button"></div>
        </div>
      </div>

      <div className="qr-pagination">
        {[1, 2, 3].map((page) => (
          <span
            key={page}
            className={`qr-dot ${page === currentPage ? "active" : ""}`}
            onClick={() => setCurrentPage(page)}
          ></span>
        ))}
        <div className="qr-next-button-mobile" onClick={handleNextPage}></div>
      </div>

      <div className="qr-year-sections-wrapper">
        <YearSection
          year="2019"
          reports={reportData2019}
          isOpen={openYear === "2019"}
          onClick={() => setOpenYear(openYear === "2019" ? "" : "2019")}
        />
        <YearSection
          year="2018"
          reports={reportData2018}
          isOpen={openYear === "2018"}
          onClick={() => setOpenYear(openYear === "2018" ? "" : "2018")}
        />
        <YearSection
          year="2017"
          reports={reportData2017}
          isOpen={openYear === "2017"}
          onClick={() => setOpenYear(openYear === "2017" ? "" : "2017")}
        />
      </div>
    </div>
  );
};

// Second Layer

const YearSection = ({
  year,
  reports,
  isOpen,
  onClick,
  isSecondLayer = false,
}) => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [reportsToShow, setReportsToShow] = useState(3);

  const updateReportsToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1800) {
      setReportsToShow(6);
    } else if (screenWidth >= 992) {
      setReportsToShow(3);
    } else if (screenWidth >= 480) {
      setReportsToShow(2);
    } else {
      setReportsToShow(1);
    }
  };

  useEffect(() => {
    updateReportsToShow();
    window.addEventListener("resize", updateReportsToShow);
    return () => {
      window.removeEventListener("resize", updateReportsToShow);
    };
  }, []);

  const handleNext = () => {
    const newIndex = (currentStartIndex + 1) % reports.length;
    setCurrentStartIndex(newIndex);
  };

  const currentReports = reports.slice(
    currentStartIndex,
    currentStartIndex + reportsToShow
  );

  const reportsToDisplay =
    currentReports.length < reportsToShow
      ? [
          ...currentReports,
          ...reports.slice(0, reportsToShow - currentReports.length),
        ]
      : currentReports;

  return (
    <div
      className={`qr-year-section ${isSecondLayer ? "qr-second-layer" : ""}`}
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      {year === "2019" && <hr className="qr-section-divider" />}
      <div className="qr-year-heading" onClick={onClick}>
        <span>{year}</span>
        <span className="qr-dropdown-arrow">
          {isOpen ? (
            <div className="qr-dropdown-arrow-circle">
              <img
                className="qr-dropdown-arrow-open"
                src={ArrowDropDownOpen}
                alt="Open arrow"
              />
            </div>
          ) : (
            <div className="qr-dropdown-arrow-circle-2">
              <img
                className="qr-dropdown-arrow-close"
                src={ArrowDropDownClose}
                alt="Closed arrow"
              />
            </div>
          )}
        </span>
      </div>
      {isOpen && (
        <div className="qr-report-container">
          <div
            className={
              isSecondLayer ? "qr-second-layer-grid" : "qr-report-grid"
            }
            style={{ gridTemplateColumns: `repeat(${reportsToShow}, 1fr)` }}
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
          >
            {reportsToDisplay.map((report) => (
              <div
                key={report.id}
                className={
                  isSecondLayer ? "qr-second-layer-item" : "qr-report-item"
                }
              >
                <img
                  src={report.image}
                  alt={report.title}
                  className="qr-report-image"
                />
                <div className="qr-report-title">{report.title}</div>
                <div className="qr-report-date">{report.date}</div>
              </div>
            ))}
          </div>
          <div className="qr-next-button-container-year">
            <div className="qr-next-button-year" onClick={handleNext}></div>
          </div>
        </div>
      )}
      <hr className="qr-section-divider" />
    </div>
  );
};

export default QuarterlyReports;
