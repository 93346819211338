import React from "react";
import "./AnnualReport.css";

const AnnualReports = () => {
  return (
    <div>
      <h2>Annual Reports</h2>
      <p>This is the content for the Annual Reports page.</p>
    </div>
  );
};

export default AnnualReports;
